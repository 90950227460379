import React from "react"
//import * as footerStyles from "./footer.module.scss"

const Footer = () => {
  return (
    <section className="py-12 lg:py-20">
      <p className="text-center text-sm text-gray-500 pt-8 px-4 border-t">
        &copy; {new Date().getFullYear()} Drone Bone AB
      </p>
    </section>
  )
}

export default Footer
