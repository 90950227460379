import React from "react"
import { Link } from "gatsby"
import Footer from "../components/new-footer"
//import { StaticImage } from "gatsby-plugin-image"
import droneBoneLogo from "../logos/drone-bone-landscape-plain.svg"
//import droneBoneLogo from "../logos/drone-bone-landscape.png"
//import * as headerStyles from "./header.module.scss"

const HeaderLink = ({ to, children, partiallyActive = true }) => {
  return (
    <Link
      to={to}
      //className={headerStyles.navItem}
      className={"text-white text-sm font-medium hover:text-blue-300"}
      activeClassName={"text-blue-400"}
      partiallyActive={partiallyActive}
    >
      {children}
    </Link>
  )
}

const MenuLink = ({ to, children, partiallyActive = true }) => {
  return (
    <Link
      to={to}
      //className={headerStyles.navItem}
      className={
        "block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded"
      }
      activeClassName={"text-gray-600"}
      partiallyActive={partiallyActive}
    >
      {children}
    </Link>
  )
}

const Header = () => {
  const menuLinks = [
    //{ title: "Start", to: "/" },
    { title: "Produkter", to: "/produkter" },
    { title: "Affärsområden", to: "/affarsomraden" },
    { title: "Blog", to: "/blog" },
    { title: "Företaget", to: "/om" },
    { title: "Kontakt", to: "/kontakt" },
  ]
  return (
    <section className="py-4 pl-0 pr-2 lg:pr-4 xl:pr-10 bg-blue-900">
      <nav className="relative">
        <div className="flex justify-between items-center">
          <Link to={"/"}>
            <img
              className="px-2 m-0 ml-4"
              src={droneBoneLogo}
              alt="Drone Bone Logotype"
              width="200px"
              height="68px"
            />
          </Link>
          <ul className="hidden lg:flex lg:w-auto xl:space-x-12 lg:space-x-8">
            {menuLinks.map(({ title, to }, i) => {
              return (
                <li className="m-0" key={i}>
                  <HeaderLink to={to} partiallyActive={i !== 0}>
                    {title}
                  </HeaderLink>
                </li>
              )
            })}
          </ul>
          <div className="lg:hidden">
            <button className="navbar-burger flex items-center p-3 ounded">
              <svg
                className="text-white block h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
              >
                <title>Meny</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <div className="hidden lg:block h-16">
            <a
              className="inline-block mr-2 py-3 px-4 xl:px-8 text-sm leading-normal border rounded text-white hover:border-gray-400 font-medium"
              href="/kontakt"
            >
              Boka demo
            </a>
            <a
              className="inline-block py-3 px-4 xl:px-8 text-sm text-white leading-normal rounded bg-blue-300 hover:bg-blue-400 font-medium transition duration-200"
              href="/logga-in"
            >
              Logga in
            </a>
          </div>
        </div>
      </nav>
      <div className="hidden navbar-menu relative z-50">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <Link
              to={"/"}
              className="mr-auto text-2xl font-medium leading-none"
            >
              <img
                className="h-16"
                src={droneBoneLogo}
                alt="Drone Bone Logotype"
                width="auto"
              />
            </Link>
            <button className="navbar-close">
              <svg
                className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              {menuLinks.map(({ title, to }, i) => {
                return (
                  <li className="mb-1" key={i}>
                    <MenuLink to={to} partiallyActive={i === 0}>
                      {title}
                    </MenuLink>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              <Link
                className="block mb-2 py-3 text-center text-sm leading-normal border rounded hover:border-gray-400 font-medium"
                to="/logga-in"
              >
                Logga in
              </Link>
              <Link
                className="block py-3 text-center text-sm text-white leading-normal rounded bg-blue-300 hover:bg-blue-400 font-medium transition duration-200"
                to="/kontakt"
              >
                Boka Demo
              </Link>
            </div>
            <Footer />
          </div>
        </nav>
      </div>
    </section>
  )
}

export default Header
