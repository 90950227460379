import React from "react"
import Header from "../components/new-header"
import Head from "../components/head"
import Footer from "../components/new-footer"
//import "../styles/index.scss"
//import * as pageLayoutStyles from "./pagelayout.module.scss"
//import "../styles/tailwind-theme.css"
//import "../styles/tailwind.min.css"
import "../assets/tailwind.css"

const PageLayout = ({ pageTitle, children }) => {
  return (
    <body class="antialiased bg-body text-body font-body">
      <Head pageTitle={pageTitle} />
      <div>
        <Header />
        {children}
        <Footer />
      </div>
    </body>
  )
}

export default PageLayout
